exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-book-demo-tsx": () => import("./../../../src/pages/book-demo.tsx" /* webpackChunkName: "component---src-pages-book-demo-tsx" */),
  "component---src-pages-customer-stories-tsx": () => import("./../../../src/pages/customer-stories.tsx" /* webpackChunkName: "component---src-pages-customer-stories-tsx" */),
  "component---src-pages-efficient-hiring-tsx": () => import("./../../../src/pages/efficient-hiring.tsx" /* webpackChunkName: "component---src-pages-efficient-hiring-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-high-volume-hiring-tsx": () => import("./../../../src/pages/high-volume-hiring.tsx" /* webpackChunkName: "component---src-pages-high-volume-hiring-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-blog-ai-in-hr-index-mdx": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=D:/a/1/s/src/blog/ai-in-hr/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-blog-ai-in-hr-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-blog-ai-recruitment-index-mdx": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=D:/a/1/s/src/blog/ai-recruitment/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-blog-ai-recruitment-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-blog-best-ai-recruiting-tools-index-mdx": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=D:/a/1/s/src/blog/best-AI-recruiting-tools/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-blog-best-ai-recruiting-tools-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-blog-how-to-choose-best-ai-platform-index-mdx": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=D:/a/1/s/src/blog/how-to-choose-best-ai-platform/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-blog-how-to-choose-best-ai-platform-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-blog-what-is-ai-interviewer-index-mdx": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=D:/a/1/s/src/blog/what-is-ai-interviewer/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-blog-what-is-ai-interviewer-index-mdx" */)
}

